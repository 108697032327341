<template>
  <section class="contact-us">
    <div class="container">
      <div class="contact-content">
        <div class="contact-content__info">
          <p class="contact-content__title main__title">
            Связаться с менеджером
          </p>
          <p class="contact-content__text main__text">
            Оставьте свои контакты и наш<br> менеджер перезвонит вам в<br> ближайший срок.
          </p>
        </div>
        <form
          class="contact-content__form"
          method="POST"
        >
          <div class="form__group">
            <p class="form__group-title">
              Ваш телефон
            </p>
            
            <input
              class="form__group-input form__group-input--phone"
              type="tel"
              placeholder="+38 (099) 99 99 999"
              required
            >
          </div>
          <div class="form__group">
            <p class="form__group-title">
              Ваш e-mail
            </p>
            
            <input
              class="form__group-input form__group-input--email"
              type="text"
              placeholder="example@gmail.com"
              required
            >
          </div>
          <button 
            class="btn btn-send" 
            type="submit"
          >
            Связаться с менеджером
          </button>
        </form>
      </div>
    </div>
    <div class="form-error form-error--hidden">
      <p>Введите данные корректно!</p>
      <img
        class="error-close"
        src="@/assets/images/close.svg"
        width="18px"
        height="18px"
        alt="close"
      >
    </div>
  </section>
</template>

<script>
export default {
    name: 'HomeContactUs', 
    mounted(){
      const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      const PHONE_REGEXP = /^[0-9 ()+-]+$/;
      const form = document.querySelector('.contact-content__form');
      let INPUT_EMAIL = document.querySelector('.form__group-input--email');
      let INPUT_PHONE = document.querySelector('input[type="tel"]')
      let formError = document.querySelector('.form-error');

      let closeBtn = document.querySelector('.error-close');
      closeBtn.addEventListener('click', ()=>{
          formError.style.opacity=0;
      })

      function validateEmail(value) {
      return EMAIL_REGEXP.test(value);
      }
      function validatePhone(value) {
      return PHONE_REGEXP.test(value);
      }
      function updateEmail() {
        if (validateEmail(INPUT_EMAIL.value)) INPUT_EMAIL.style.borderColor = 'green';
        else INPUT_EMAIL.style.borderColor = 'red';
      }
      function updatePhone() {
        if (validatePhone(INPUT_PHONE.value)) INPUT_PHONE.style.borderColor = 'green';
        else INPUT_PHONE.style.borderColor = 'red';
      }

      form.addEventListener("submit", e=>{
        e.preventDefault();
        updateEmail() // проверяем имейл
        updatePhone() // проверяем телефон

        let resultPhone = validatePhone(INPUT_PHONE.value)
        let resultEmail = validateEmail(INPUT_EMAIL.value)
        if (resultEmail === false || resultPhone === false){
          formError.classList.replace('form-error--hidden', 'form-error--show');
          setTimeout(()=>{
            formError.classList.replace('form-error--show', 'form-error--hidden')
          }, 4000);
        }
        });

      }
}
</script>
